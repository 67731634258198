import React from 'react';
import Header from '../components/Header';

const ProjectSPage = () => {
  return (
    <div className="fade-in">
      <Header />
      <section className="container project-s-section">
        <h2>Project S</h2>
        <p>Click the link below to view the video:</p>
        
        <a 
          href="https://drive.google.com/drive/folders/1cH5Jsm4PazIADM76tvww9MGRawzjYmqV?usp=sharing" 
          target="_blank" 
          rel="noopener noreferrer"
          style={{ 
            display: 'inline-block', 
            padding: '0.75rem 1.5rem', 
            backgroundColor: '#F26B0F', 
            color: '#fff', 
            textDecoration: 'none', 
            borderRadius: '4px', 
            marginTop: '1rem',
            fontWeight: 'bold'
          }}
        >
          Watch Video on Google Drive
        </a>
      </section>
    </div>
  );
}

export default ProjectSPage;
