// src/pages/ChatPage.js
import React, { useState, useEffect, useRef } from 'react';
import { database } from '../firebase';
import { ref, onValue, push, set, remove } from 'firebase/database';
import Header from '../components/Header';

function ChatPage() {
  const [role, setRole] = useState(''); // 'Me' or 'You'
  const [message, setMessage] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [messages, setMessages] = useState([]);
  const chatBoxRef = useRef(null);
  const [viewedImages, setViewedImages] = useState({});
  const [lastSentTimestamp, setLastSentTimestamp] = useState(null);

  // Select role
  const selectRole = (selectedRole) => setRole(selectedRole);

  // Send a text message
  const sendMessage = () => {
    if (message.trim()) {
      const chatMessage = {
        sender: role,
        text: message,
        timestamp: Date.now(),
        type: 'text', // Distinguish from 'image'
      };
      const messagesRef = ref(database, 'messages');
      push(messagesRef, chatMessage);
      setMessage('');
    }
  };

  // Handle Enter key press
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      sendMessage();
    }
  };

  // Handle image selection
  const handleImageSelection = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImageFile(e.target.files[0]);
    }
  };

  // Convert image to Base64 and send
  const sendImage = async () => {
    if (!imageFile) return;
    try {
      const base64Data = await convertToBase64(imageFile);
      const chatMessage = {
        sender: role,
        imageData: base64Data, // Store Base64 in Firebase
        timestamp: Date.now(),
        type: 'image',
      };
      const messagesRef = ref(database, 'messages');
      push(messagesRef, chatMessage);
      setImageFile(null);
    } catch (error) {
      console.error('Error converting image:', error);
    }
  };

  // Utility function: Convert file to Base64
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  // Realtime listener for messages
  useEffect(() => {
    const messagesRef = ref(database, 'messages');

    const unsubscribe = onValue(messagesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        let messagesData = Object.entries(data).map(([key, value]) => ({
          id: key,
          ...value,
        }));
        messagesData.sort((a, b) => a.timestamp - b.timestamp);
        setMessages(messagesData);
      } else {
        setMessages([]);
      }
    });

    return () => unsubscribe();
  }, []);

  // Scroll to the latest message
  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }

    if (messages.length > 0) {
      const lastMessage = messages[messages.length - 1];
      setLastSentTimestamp(lastMessage.timestamp);
    } else {
      setLastSentTimestamp(null);
    }
  }, [messages]);

  // Clear all messages
  const clearMessages = () => {
    const messagesRef = ref(database, 'messages');
    set(messagesRef, null);
    setMessages([]);
    setLastSentTimestamp(null);
  };

  // Format timestamp
  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString();
  };

  // Handle viewing an image (removes after 5 seconds)
  const handleViewImage = (msg) => {
    setViewedImages((prev) => ({ ...prev, [msg.id]: true }));

    // Remove from Firebase after 5 seconds
    setTimeout(() => {
      const messageRef = ref(database, `messages/${msg.id}`);
      remove(messageRef).catch((error) =>
        console.error('Error removing image:', error)
      );
    }, 5000);
  };

  // Role Selection Screen
  if (!role) {
    return (
      <>
        <Header />
        <div style={styles.roleContainer}>
          <h1 style={styles.roleTitle}>Select Your Role</h1>
          <button onClick={() => selectRole('Me')} style={styles.roleButton}>
            Me
          </button>
          <button onClick={() => selectRole('You')} style={styles.roleButton}>
            You
          </button>
        </div>
      </>
    );
  }

  // Chat UI
  return (
    <>
      <Header />
      <div style={styles.container}>
        <div style={styles.chatBox} ref={chatBoxRef}>
          {messages.map((msg) => (
            <div
              key={msg.id}
              style={{
                ...styles.messageBubble,
                backgroundColor: msg.sender === 'Me' ? '#7e57c2' : '#e0e0e0',
                color: msg.sender === 'Me' ? '#fff' : '#333',
              }}
            >
              <strong style={{ color: msg.sender === 'Me' ? '#ffd700' : '#6a5acd' }}>
                {msg.sender}:
              </strong>
              {msg.type === 'text' && <span>{msg.text}</span>}

              {msg.type === 'image' && msg.imageData && (
                <>
                  {!viewedImages[msg.id] ? (
                    <button
                      onClick={() => handleViewImage(msg)}
                      style={styles.viewImageButton}
                    >
                      View Image
                    </button>
                  ) : (
                    <img src={msg.imageData} alt="Sent" style={styles.chatImage} />
                  )}
                </>
              )}
            </div>
          ))}
        </div>

        {/* Message Input */}
        <div style={styles.inputContainer}>
          <input
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Type a message..."
            style={styles.input}
          />
          <button onClick={sendMessage} style={styles.sendButton}>
            📩
          </button>
        </div>

        {/* Image Upload */}
        <div style={styles.inputContainer}>
          <input type="file" onChange={handleImageSelection} style={styles.fileInput} />
          <button onClick={sendImage} style={styles.sendButton}>📷</button>
        </div>

        {/* Clear Chat */}
        <button onClick={clearMessages} style={styles.clearButton}>Clear Chat</button>

        {/* Timestamp */}
        {lastSentTimestamp && (
          <div style={styles.timestamp}>
            Last message sent at {formatTime(lastSentTimestamp)}
          </div>
        )}
      </div>
    </>
  );
}

// **Updated Styles**
const styles = {
  roleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    background: 'linear-gradient(135deg, #483D8B, #6C63B0)',
    color: '#fff',
  },
  roleTitle: {
    fontSize: '24px',
    marginBottom: '1rem',
  },
  roleButton: {
    padding: '12px 20px',
    margin: '10px',
    fontSize: '18px',
    backgroundColor: '#7e57c2',
    color: '#fff',
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    transition: 'transform 0.2s ease',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '100vh',
    background: 'linear-gradient(135deg, #483D8B, #6C63B0)',
    color: '#fff',
    padding: '20px',
  },
  chatBox: {
    width: '100%',
    maxWidth: '600px',
    height: '65vh',
    overflowY: 'auto',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    padding: '15px',
    borderRadius: '12px',
    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
  },
  messageBubble: {
    padding: '12px',
    borderRadius: '16px',
    marginBottom: '10px',
    maxWidth: '80%',
    wordBreak: 'break-word',
    display: 'flex',
    alignItems: 'center', // Ensures vertical alignment
    justifyContent: 'flex-start', // Aligns text/images consistently
    gap: '10px', // Ensures space between text and image if needed
  },
  chatImage: {
    maxWidth: '200px',
    maxHeight: '200px',
    borderRadius: '8px',
    display: 'block',
    margin: 'auto', // Centers image within the chat bubble
  },
  viewImageButton: {
    backgroundColor: '#3825a6',
    color: '#FFF',
    padding: '6px 10px',
    borderRadius: '4px',
    cursor: 'pointer',
    display: 'block',
    margin: 'auto',
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    maxWidth: '600px',
    marginTop: '10px',
  },
  input: {
    flex: 1,
    padding: '12px',
    borderRadius: '12px',
    border: 'none',
    background: '#fff',
    fontSize: '16px',
    outline: 'none',
    color: '#333',
  },
  sendButton: {
    padding: '12px',
    marginLeft: '10px',
    backgroundColor: '#ffd700',
    border: 'none',
    borderRadius: '12px',
    cursor: 'pointer',
    transition: 'transform 0.2s ease',
  },
  fileInput: {
    flex: 1,
    color: '#FFF',
    backgroundColor: '#7e57c2',
    border: '1px solid #fff',
    borderRadius: '6px',
    padding: '8px',
  },
  clearButton: {
    padding: '12px',
    backgroundColor: '#ff4c4c',
    color: '#fff',
    border: 'none',
    borderRadius: '12px',
    cursor: 'pointer',
    width: '100%',
    maxWidth: '600px',
    marginTop: '10px',
  },
  timestamp: {
    fontSize: '14px',
    color: '#ddd',
    marginTop: '10px',
  },
};

export default ChatPage;
