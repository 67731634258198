// src/pages/GalleryPage.js
import React, { useState } from 'react';
import Header from '../components/Header';

function GalleryPage() {
  const [isVerified, setIsVerified] = useState(false);
  const [pin, setPin] = useState('');
  const [error, setError] = useState('');

  const correctPin = '8021';

  const handleVerifyPin = () => {
    if (pin === correctPin) {
      setIsVerified(true);
      setError('');
    } else {
      setError('Incorrect passcode. Please try again.');
    }
  };

  return (
    <>
      <Header />
      <div className="gallery-container">
        {!isVerified ? (
          <div className="sensitive-warning">
            <h2>Warning</h2>
            <p>
              You’re about to see some images that could be sensitive.
              <br />
              Please verify your passcode to continue.
            </p>
            <input
              type="password"
              placeholder="Enter PIN"
              value={pin}
              onChange={(e) => setPin(e.target.value)}
              className="pin-input"
            />
            <button onClick={handleVerifyPin}>Verify</button>
            {error && <p className="error">{error}</p>}
          </div>
        ) : (
          <>
            <h2>Gallery</h2>
            <div className="images-grid">
              {/* 
                Using loading="lazy" for each img so the browser only loads images 
                once they're near the viewport.
              */}
              <img src="/images/pic1.png" loading="lazy" alt="Pic 1" />
              <img src="/images/pic2.png" loading="lazy" alt="Pic 2" />
              <img src="/images/pic3.png" loading="lazy" alt="Pic 3" />
              <img src="/images/pic4.png" loading="lazy" alt="Pic 4" />
              <img src="/images/pic5.png" loading="lazy" alt="Pic 5" />
              <img src="/images/pic6.png" loading="lazy" alt="Pic 6" />
              <img src="/images/pic7.png" loading="lazy" alt="Pic 7" />
              <img src="/images/pic8.png" loading="lazy" alt="Pic 8" />
              <img src="/images/pic9.png" loading="lazy" alt="Pic 9" />
              <img src="/images/pic10.png" loading="lazy" alt="Pic 10" />
              <img src="/images/pic11.png" loading="lazy" alt="Pic 11" />
              <img src="/images/pic12.png" loading="lazy" alt="Pic 12" />
              <img src="/images/pic13.png" loading="lazy" alt="Pic 13" />
              <img src="/images/pic14.png" loading="lazy" alt="Pic 14" />
              <img src="/images/pic15.png" loading="lazy" alt="Pic 15" />
              <img src="/images/pic16.png" loading="lazy" alt="Pic 16" />
              <img src="/images/pic17.png" loading="lazy" alt="Pic 17" />
              <img src="/images/pic18.png" loading="lazy" alt="Pic 18" />
              <img src="/images/pic19.png" loading="lazy" alt="Pic 19" />
              <img src="/images/pic20.png" loading="lazy" alt="Pic 20" />
              <img src="/images/pic21.png" loading="lazy" alt="Pic 21" />
              <img src="/images/pic22.png" loading="lazy" alt="Pic 22" />
              <img src="/images/pic23.png" loading="lazy" alt="Pic 23" />
              <img src="/images/pic24.png" loading="lazy" alt="Pic 24" />
              <img src="/images/pic25.png" loading="lazy" alt="Pic 25" />
              <img src="/images/pic26.png" loading="lazy" alt="Pic 26" />
              <img src="/images/pic27.png" loading="lazy" alt="Pic 27" />
              <img src="/images/pic28.png" loading="lazy" alt="Pic 28" />
              <img src="/images/pic29.png" loading="lazy" alt="Pic 29" />
              <img src="/images/pic30.png" loading="lazy" alt="Pic 30" />
              <img src="/images/pic31.png" loading="lazy" alt="Pic 31" />
              <img src="/images/pic32.png" loading="lazy" alt="Pic 32" />
              <img src="/images/pic33.png" loading="lazy" alt="Pic 33" />
              <img src="/images/pic34.png" loading="lazy" alt="Pic 34" />
              <img src="/images/pic35.png" loading="lazy" alt="Pic 35" />
              <img src="/images/pic36.png" loading="lazy" alt="Pic 36" />
              <img src="/images/pic37.png" loading="lazy" alt="Pic 37" />
              <img src="/images/pic38.png" loading="lazy" alt="Pic 38" />
              <img src="/images/pic39.png" loading="lazy" alt="Pic 39" />
              <img src="/images/pic40.png" loading="lazy" alt="Pic 40" />
              <img src="/images/pic41.png" loading="lazy" alt="Pic 41" />
              <img src="/images/pic42.png" loading="lazy" alt="Pic 42" />
              <img src="/images/pic43.png" loading="lazy" alt="Pic 43" />
              <img src="/images/pic44.png" loading="lazy" alt="Pic 44" />
              <img src="/images/pic45.png" loading="lazy" alt="Pic 45" />
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default GalleryPage;
