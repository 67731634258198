import React, { useState, useEffect } from 'react';
import Teleprompter from '../components/Teleprompter';
import { useNavigate } from 'react-router-dom';

const IntroPage = () => {
  const navigate = useNavigate();
  const [typingDone, setTypingDone] = useState(false);

  const longText = "The first time I saw you, it wasn’t just the way you smiled—it was the way your presence lit up the room. I didn’t know it then, but that light would come to guide me through some of the most extraordinary moments of my life. This past year with you has been a journey. A real, beautiful, imperfect journey. I love everything about you—the quirks that make me laugh, the way you tilt your head when you’re thinking, the fire in your eyes when you’re passionate, and the quiet moments when you let me see the softer side of you. No matter what I do or say or buy, nothing will ever fully express my love for you. It’s infinite, boundless, and grows every day in ways I didn’t even think possible. We’ve had an amazing year together. Sure, it wasn’t always easy. There were times we didn’t see eye to eye, times when we felt stretched too thin, or when life threw curveballs we weren’t expecting. But those moments—those ups and downs—were what made everything feel real. It’s what deepened my love for you. Each argument we resolved, each tear we wiped away, and each laugh we shared afterward became bricks that built the foundation of “us.” Every second with you, even the hard ones, was a second I wouldn’t trade for anything in the world. And the good moments? Those were phenomenal. The kind of moments I’ll replay in my mind when I’m old and gray, still smiling like a fool because I got to share them with you. And now, as I sit here reflecting on everything, I can’t help but feel overwhelmed with gratitude. I get to spend my life with you. You’re my partner, my best friend, my everything. I’m so incredibly lucky to have you by my side. Lillu, enjoy this little reminder of how much you mean to me. No words, no gift, no grand gesture will ever be enough to encapsulate my love for you—but I’ll keep trying every day, for the rest of our lives. I love you endlessly.";

  useEffect(() => {
    const typingDuration = longText.length * 30;
    const timer = setTimeout(() => {
      setTypingDone(true);
    }, typingDuration + 500);
    return () => clearTimeout(timer);
  }, [longText]);

  const handleSkip = () => {
    navigate('/main');
  }

  return (
    <div className="intro-page fade-in">
      <Teleprompter text={longText} />
      {typingDone && (
        <button onClick={() => navigate('/main')} style={{ marginTop: '1em' }}>Next</button>
      )}
      <button onClick={handleSkip} className="skip-button">
        Skip
      </button>
    </div>
  );
}

export default IntroPage;
