import React, { useState, useRef } from 'react';
import Header from '../components/Header';

const tiles = [
  { label: "When you're feeling sad", src: "/audio/audio1.mp3" },
  { label: "When you're feeling lonely", src: "/audio/audio2.mp3" },
  { label: "When you're feeling stressed or overwhelmed", src: "/audio/audio3.mp3" },
  { label: "When you're feeling anxious", src: "/audio/audio4.mp3" },
  { label: "When you're feeling angry or frustrated", src: "/audio/audio5.mp3" },
  { label: "When you're feeling unmotivated or tired", src: "/audio/audio6.mp3" },
  { label: "When you're mad at me", src: "/audio/audio7.mp3" },
  { label: "When you need confidence", src: "/audio/audio8.mp3" },
  { label: "When you're missing me", src: "/audio/audio9.mp3" },
  { label: "When you can't sleep", src: "/audio/audio10.mp3" },
  { label: "When you're feeling bored", src: "/audio/audio11.mp3" },
  { label: "When you need a laugh", src: "/audio/audio12.mp3" },
  { label: "When you're feeling upset or hurt", src: "/audio/audio13.mp3" },
  { label: "When you're feeling nostalgic", src: "/audio/audio14.mp3" },
  { label: "When you want a quick 'I love you'", src: "/audio/audio15.mp3" },
];

function MoodPage() {
  const [confirmed, setConfirmed] = useState(false);
  const [playingSrc, setPlayingSrc] = useState(null);
  const audioRef = useRef(null);

  const handleTileClick = (src) => {
    // If user clicks the same tile that's already playing, we can toggle playback or do nothing
    // For simplicity, if the user clicks a different tile, set a new src and play
    if (src !== playingSrc) {
      setPlayingSrc(src);
      // The actual playback will happen in the useEffect below or once the <audio> ref changes its src
    } else {
      // Optional: Pause if the same tile is playing
      // audioRef.current?.pause();
      // setPlayingSrc(null);
    }
  };

  // Whenever playingSrc changes, update the audio element's src and play
  React.useEffect(() => {
    if (audioRef.current && playingSrc) {
      audioRef.current.src = playingSrc;
      audioRef.current.play().catch(err => console.log("Audio play error:", err));
    }
  }, [playingSrc]);

  const handleAudioEnded = () => {
    // Clear the playing track once the audio ends
    setPlayingSrc(null);
  };

  return (
    <>
      <Header />
      <div className="mood-container">
        {!confirmed ? (
          <div className="confirmation">
            <h3>One Quick Check</h3>
            <p>
              We’ve got audio content here. Make sure it’s okay for those around you 
              to hear. Are you ready to continue?
            </p>
            <button onClick={() => setConfirmed(true)}>Yes, let's continue</button>
          </div>
        ) : (
          <div className="tiles-container">
            {tiles.map((tile, index) => (
              <div key={index} className="tile" onClick={() => handleTileClick(tile.src)}>
                {tile.label}
              </div>
            ))}
          </div>
        )}

        {/* Audio Player with controls, only shown if playingSrc is set */}
        {playingSrc && (
          <div className="audio-player">
            <audio
              ref={audioRef}
              controls
              autoPlay
              onEnded={handleAudioEnded}
              style={{ width: '100%' }}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default MoodPage;
