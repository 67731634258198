import AboutImage from '../assets/images/about.png';

const AboutSection = () => {
  return (
    <section className="container about-section fade-in">
      <div className="about-text">
        <h2>About Us</h2>
        <p>
          To think it all started with a Starbucks coffee. Just one cup, a couple of carpools, way too many talks, texts, and shared reels. Oh, and let’s not forget the absolutely random (and slightly awkward) flirting attempts—mostly on your part, because I was too oblivious to notice!

          And then, somehow, it all came down to a little “nvm.” Just that tiny word, and suddenly everything clicked.

          It’s funny to look back and think how something so simple turned into something so big. But that’s us—simple, a little silly, and absolutely perfect in our own way.
        </p>
      </div>
      <div className="about-image">
        <img 
          src={AboutImage} 
          alt="About" 
        />
      </div>
    </section>
  );
}

export default AboutSection;
