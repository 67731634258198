// src/pages/HomePage.js
import React from 'react';
import Header from '../components/Header';
import { useNavigate } from 'react-router-dom';

function HomePage() {
  const navigate = useNavigate();

  return (
    <>
      <Header />
      <div className="home-container">
        <div className="options-container">
          <button onClick={() => navigate('/gallery')}>Gallery</button>
          <button onClick={() => navigate('/mood')}>Mood</button>
          <button onClick={() => navigate('/chat')}>Chat</button>
          {/* New button for SOS notification */}
          <button onClick={() => navigate('/sos')}>Send Notification</button>
        </div>
      </div>
    </>
  );
}

export default HomePage;
