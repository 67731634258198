// src/App.js
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import IntroPage from './pages/IntroPage';
import MainPage from './pages/MainPage';
import ProjectSPage from './pages/ProjectSPage';
import ProtectedRoute from './components/ProtectedRoute';

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/login" />} />
      <Route path="/login" element={<LoginPage />} />
      <Route 
        path="/intro" 
        element={
          <ProtectedRoute>
            <IntroPage />
          </ProtectedRoute>
        } 
      />
      <Route 
        path="/main" 
        element={
          <ProtectedRoute>
            <MainPage />
          </ProtectedRoute>
        } 
      />
      <Route 
        path="/project-s" 
        element={
          <ProtectedRoute>
            <ProjectSPage />
          </ProtectedRoute>
        } 
      />
    </Routes>
  );
}

export default App;
