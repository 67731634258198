// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyDRlWIBKKJVtowVul3up82PtpBWgFP_x6c",
  authDomain: "lillu-d2f34.firebaseapp.com",
  projectId: "lillu-d2f34",
  storageBucket: "lillu-d2f34.firebasestorage.app",
  messagingSenderId: "312376347638",
  appId: "1:312376347638:web:8f02efb0037ef8804a55d7"
};


const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
