// src/pages/SosPage.js
import React, { useState } from "react";
import Header from "../components/Header";
import emailjs from "@emailjs/browser";  // <-- Import EmailJS

function SosPage() {
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const sendSosMessage = async () => {
    setLoading(true);
    setError("");
    setSuccess(false);

    const templateParams = {
      message: message || "SOS Alert! Please check messages ASAP.",
    };

    emailjs
      .send(
        "service_r4o9fvq",  // Replace with your EmailJS Service ID
        "template_6t21q7v", // Replace with your EmailJS Template ID
        templateParams,
        "V0J2BJXMfcZKm5k5_"  // Replace with your EmailJS Public Key
      )
      .then(() => {
        setSuccess(true);
      })
      .catch((err) => {
        setError("Failed to send SOS. Please try again.");
        console.error("EmailJS Error:", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  
  const sendEmail = () => {
    emailjs.send(
      "your_service_id",  // Replace with EmailJS Service ID
      "your_template_id", // Replace with EmailJS Template ID
      { message: "SOS! Please check messages." },
      "your_public_key"   // Replace with EmailJS Public Key
    )
    .then(() => alert("SOS Sent!"))
    .catch(err => console.error("Error:", err));
  };
  

  return (
    <>
      <Header />
      <div className="sos-container">
        <h2>Send SOS Message</h2>
        <p>Are you sure you want to send an SOS alert?</p>

        <textarea
          placeholder="Add an optional message..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          className="sos-textarea"
        ></textarea>

        <div className="sos-buttons">
          <button onClick={sendSosMessage} disabled={loading}>
            {loading ? "Sending..." : "Send SOS"}
          </button>
          <button onClick={() => window.history.back()}>Go Back</button>
        </div>

        {success && <p className="success-message">SOS Sent Successfully! 🚀</p>}
        {error && <p className="error-message">{error}</p>}
      </div>
    </>
  );
}

export default SosPage;
