// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase } from "firebase/database";  // <-- Import for Realtime Database

const firebaseConfig = {
    apiKey: "AIzaSyDRlWIBKKJVtowVul3up82PtpBWgFP_x6c",
    authDomain: "lillu-d2f34.firebaseapp.com",
    databaseURL: "https://lillu-d2f34-default-rtdb.firebaseio.com/",  // <-- Corrected URL
    projectId: "lillu-d2f34",
    storageBucket: "lillu-d2f34.appspot.com",  // <-- Fixed Storage Bucket URL
    messagingSenderId: "312376347638",
    appId: "1:312376347638:web:8f02efb0037ef8804a55d7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const database = getDatabase(app);  // <-- Initialize Realtime Database AFTER `app`

export { auth, database }; // <-- Now exporting database properly
