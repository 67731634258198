import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase';

function ProtectedRoute({ children }) {
  const [user, loading] = useAuthState(auth);

  useEffect(() => {
    if (!user) return;

    let logoutTimer;

    const resetLogoutTimer = () => {
      clearTimeout(logoutTimer);
      logoutTimer = setTimeout(() => {
        auth.signOut();
      }, 500000); // 30 seconds in milliseconds
    };

    // Start the timer when the component mounts
    resetLogoutTimer();

    // Listen for user activity on both desktop and mobile devices
    window.addEventListener('mousemove', resetLogoutTimer);
    window.addEventListener('keydown', resetLogoutTimer);
    window.addEventListener('touchstart', resetLogoutTimer);
    window.addEventListener('touchmove', resetLogoutTimer);

    return () => {
      clearTimeout(logoutTimer);
      window.removeEventListener('mousemove', resetLogoutTimer);
      window.removeEventListener('keydown', resetLogoutTimer);
      window.removeEventListener('touchstart', resetLogoutTimer);
      window.removeEventListener('touchmove', resetLogoutTimer);
    };
  }, [user]);

  if (loading) return <div>Loading...</div>;
  if (!user) return <Navigate to="/" />;

  return children;
}

export default ProtectedRoute;
