import React, { useEffect, useState, useRef } from 'react';

const Teleprompter = ({ text }) => {
  const [displayedText, setDisplayedText] = useState('');
  const containerRef = useRef(null);

  useEffect(() => {
    let idx = 0;
    const interval = setInterval(() => {
      if (idx < text.length) {
        setDisplayedText((prev) => prev + text.charAt(idx));
        idx++;
      } else {
        clearInterval(interval);
      }
    }, 30); // typing speed
    return () => clearInterval(interval);
  }, [text]);

  useEffect(() => {
    // Whenever displayedText changes, scroll to bottom of the teleprompter
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [displayedText]);

  return (
    <div className="teleprompter" ref={containerRef}>
      {displayedText}
    </div>
  );
};

export default Teleprompter;
