// src/components/Header.js
import React from 'react';
import { auth } from '../firebase';
import { signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

function Header() {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (err) {
      console.error("Error logging out: ", err);
    }
  };

  const handleLogoClick = () => {
    navigate('/home');
  };

  return (
    <header className="header">
      <div className="logo" onClick={handleLogoClick}>
        lillu
      </div>
      <button className="logout-button" onClick={handleLogout}>Logout</button>
    </header>
  );
}

export default Header;
