import React from 'react';
import Header from '../components/Header';
import AboutSection from '../components/AboutSection';
import { useNavigate } from 'react-router-dom';

const MainPage = () => {
  const navigate = useNavigate();
  return (
    <div className="fade-in">
      <Header />
      <main className="container">
        <AboutSection />
        <section className="canva-section">
          <div style={{
            position: 'relative', 
            width: '100%', 
            height: '0', 
            paddingTop: '56.2500%', 
            boxShadow: '0 2px 8px 0 rgba(63,69,81,0.16)', 
            marginTop: '1.6em', 
            marginBottom: '0.9em', 
            overflow: 'hidden', 
            borderRadius: '8px',
          }}>
            <iframe
              loading="lazy"
              style={{
                position: 'absolute', 
                width: '100%', 
                height: '100%', 
                top: 0, left: 0, 
                border: 'none', 
                padding: 0, 
                margin: 0
              }}
              src="https://www.canva.com/design/DAGYf5Ax1Ug/S_N0QK-a8tcQYOc6nIhpGQ/view?embed" 
              allowFullScreen 
              title="Canva Presentation"
            />
          </div>
          <a 
            href="https://www.canva.com/design/DAGYf5Ax1Ug/S_N0QK-a8tcQYOc6nIhpGQ/view?utm_content=DAGYf5Ax1Ug&utm_campaign=designshare&utm_medium=embeds&utm_source=link" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            Green and Pink Illustrative Christmas Scene Blank Wish List Christmas Presentation by Shaik Hatim
          </a>
        </section>
        <section style={{ textAlign: 'center', padding: '2em 0' }}>
          <button onClick={() => navigate('/project-s')}>
            Check out Project S
          </button>
        </section>
      </main>
    </div>
  );
}

export default MainPage;
